<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b">
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Berikut adalah <b>Daftar Pendonor</b> yang ada di Klinik Hayandra
      </div>
      <b-button squared variant="success" @click="$router.push('/donor-recipients/add')" v-if="btn">Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-end mt-n3">
              <div class="col-md-3">
                <treeselect
                  v-model="filter.blood_type_id"
                  :multiple="false"
                  :options="blood_type"
                  placeholder="Cari Berdasar Gol. Darah"
                  @select="filterByBloodType" />
              </div>
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Nama Pendonor"
                    @keyup="filterByName"></b-form-input>
                </b-input-group>
              </div>
            </div>
            <b-table striped hover :items="items" :fields="fields">
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }" />
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="
                    $router.push({ path: '/donor-recipients/detail/' + data.item.id })
                  ">
                  <i class="fas fa-eye px-0"></i>
                </b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="
                    $router.push({ path: '/donor-recipients/edit/' + data.item.id })
                  "
                  v-if="btn">
                  <i class="fas fa-edit px-0"></i>
                </b-button>
                <!-- <b-button
                    size="sm"
                    class="btn-danger"
                  > Hapus </b-button> -->
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"></b-pagination>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue";
import { debounce } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
        blood_type_id: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          labebl: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "name",
          sortable: true,
        },
        {
          key: "blood_type_name",
          label: "Golongan Darah",
          sortable: true,
        },
        {
          key: "age",
          label: "Umur",
          sortable: true,
        },
        {
          key: "gender",
          label: "Jenis Kelamin",
          sortable: true,
        },
        {
          key: "phone",
          label: "Nomor Handphone",
          sortable: false,
        },
        {
          key: "id_card_number",
          label: "No KTP",
          sortable: false,
        },
        {
          key: "quantity",
          label: "Jumlah Kantong Darah",
          sortable: false,
        },
        // {
        //   key: "birt_date",
        //   label: "Tanggal Lahir",
        //   sortable: false,
        // },
        {
          key: "receipt_date_display",
          label: "Tanggal Menerima Donor",
          sortable: true,
        },
        {
          ke: "email",
          label: "Email",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      blood_type: [],
      // User Access
      btn: false,
    };
  },

  methods: {
    pageOnClick(page) {
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&blood_type_id=${this.filter.blood_type_id}`;
      let response = await module.paginate(
        `donor-recipients`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination
      this.totalRows = pagination.total

      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }

      this.items = response.data;
      this.totalRows = pagination.total;
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("donor-recipients/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    async getBloodTypeOption() {
      let response = await module.setTreeSelect("blood-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.blood_type = response.data;
        this.blood_type.unshift({
          label: "Filter Berdasar Gol. Darah",
          id: "",
          isDisabled: true,
        });
      }
    },

    filterByName() {
      debounce(() => { this.generalFilter() }, 500)
    },

    filterByBloodType(evt) {
      this.filter.blood_type_id = evt.id
      this.generalFilter()
    },

    generalFilter() {
      this.pagination()
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "2402") {
          this.btn = true
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bank Darah", route: "/blood-bank" },
      { title: "Daftar Penerima Donor" },
    ]);

    this.pagination();
    this.getBloodTypeOption();
    this.setActiveButton()
  },

  watch: {
    'filter.blood_type_id': function (newVal, oldVal) {
      if (typeof newVal === 'undefined') {
        this.filter.blood_type_id = ''
        this.generalFilter()
      }
    }
  },
};
</script>

<style></style>